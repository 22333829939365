import * as React from "react";
import { Container, Nav, Navbar, NavbarBrand } from "react-bootstrap";
import logo from "../images/logo-round.svg";

const Header = () => {
  return (
    <Container>
      <header className="mb-3">
        <Navbar expand="lg" className="pt-3 pt-md-4 pb-3 pb-md-4">
          <Container>
            <NavbarBrand>
              <img src={logo} alt="Beauty Foundation" />
            </NavbarBrand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/female-procedures">For Women</Nav.Link>
                <Nav.Link href="/male-procedures">For Men</Nav.Link>
                <Nav.Link href="/non-profit">Non-Profit</Nav.Link>
                <Nav.Link href="/contact">Contact</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </Container>
  );
};

export default Header;
